.send-button {

    &:hover {
        box-shadow: 0.2rem .5rem 2.5rem rgba(0, 0, 0, .175) !important;
        color: #2dcfa7 !important;
        font-weight: 600;
    }
}

.reset-button {
    &:hover {
        box-shadow: 0.2rem .5rem 2.5rem rgba(255, 0, 0, 0.175) !important;
        border-color: red !important;
        color: red !important;
    }

    &:focus {
        outline: none !important;
    }

    &:focus-visible {
        outline: 2px solid red !important;
        outline-offset: 2px !important;
    }
}